import { useEffect, useState } from "react";
import googleImage from "../Images/googleImage.jpg"

function GoogleReviewsBadge(){
    //Storing the results from the API
    const [state,setState] = useState({found : false, items : []})

    //Collecting the information from the API, this is used to create the badge icon
    useEffect(() => {
        try{
            fetch("https://featurable.com/api/v1/widgets/e58207e3-7800-478a-8fdd-1de6af817043")
            .then((res) => res.json())
            .then((json) => {
                setState({
                    found:true,
                    items: json
                })
            })
        }
        catch{
            console.log("Error getting file")
        }
    },[])
    
    return(
        <div>
            {/* Making my own badge icon to be displayed */}
            {state.found ? 
                <div className="reviewsBadgeArea flexItems flexDisplay">
                    <div className="reviewsBadge">
                        <div className="reviewsBadgeTop">
                            <p className="subheading">Farnham and District Window Cleaning</p>
                        </div>
                        <div className="reviewsBadgeBottom flexDisplay largeGap">
                            <img src={googleImage} alt="" />
                            <div className="reviewRating flexDisplay column">
                                <div className="starRating flexDisplay">
                                    <div className="starsDisplay flexDisplay">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="css-ctx7ii"><path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd"></path></svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="css-ctx7ii"><path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd"></path></svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="css-ctx7ii"><path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd"></path></svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="css-ctx7ii"><path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd"></path></svg>
                                        {state.items.averageRating == 5 ? 
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="css-ctx7ii"><path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd"></path></svg> : <div style={{display:"none"}}></div>
                                        }
                                    </div>
                                    <p className="smallSubheading">{state.items.averageRating}</p>
                                </div>
                            </div>
                            <p className="smallSubheading">Based on <a href="https://www.google.com/maps?cid=8308180695557838932" target="_blank">{state.items.totalReviewCount} reviews</a></p>
                            <button onClick={() => window.open("https://www.google.com/maps/place/Farnham+%26+District+Window+Cleaning/@51.2029885,-0.8065671,17z/data=!3m1!4b1!4m6!3m5!1s0x48742da4a84b46af:0x734c9652f07b9854!8m2!3d51.2029885!4d-0.8065671!16s%2Fg%2F11hfbmzbh4?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D")} className="flexItems"><p className="subheading">Write a Review</p></button>
                        </div>
                    </div>
                </div> 
                : <div style={{display:"none"}}></div>
            }
        </div>
    )
}

export default GoogleReviewsBadge