import React from "react";
import GoogleMapReact from "google-map-react";

const MapAPIImplementation = () => {
  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
    position: { lat: 51.2160, lng: -0.7990 },
    map,
    title: 'Farnham and District Window Cleaning'
    });
    return marker;
   };

  return (
    <div className="map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyATfNBhJ6TTjo4ya8lDlP0SMIzS7jM7HXc" }}
        defaultCenter={{ lat: 51.2160, lng: -0.7985 }}
        defaultZoom={11}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      >
      </GoogleMapReact>
    </div>
  );
}

export default MapAPIImplementation;
