import emailjs from "@emailjs/browser";

//Function to send me an email, using the form
function SendEmail(name, email,phoneNmb,title, message) {
  emailjs.init("F7xzW3kuafXKSEsVA");
  //Validating any inputs
  if (name == "" || email == "" || phoneNmb == "" || title == "" || message == "") {
    return "One or more email inputs are empty..."
  }

  //Sending the email
  emailjs
    .send("service_96rkkea","template_lnylt2x", {
      from_name: name,
      email: email,
      phoneNmb : phoneNmb,
      title: title,
      message: message,
    })
    .then(
      (response) => {
        //Noting success
        return "Email Successfully Sent"
      },
      (error) => {
        return "An error has occurred when sending the email"
      }
    );
}

export default SendEmail;
